body {
  background-color: #121212;
  color: #FFFFFF;
  font-family: Arial, sans-serif;
}

.header {
  background-color: #1E1E1E;
  padding: 10px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-and-text {
  display: flex;
  align-items: center;
}

.fox-logo {
  height: 60px;
  margin-right: 10px;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.company-name {
  color: #FF9900;
  margin: 0;
}

.slogan {
  color: #FFFFFF;
  margin: 0;
}

.contact-info {
  color: #FFFFFF;
  text-align: right;
}

.contact-text {
  margin: 0;
}

.navigation {
  background-color: #1E1E1E;
  padding: 10px;
  text-align: center;
}

button, .amplify-button {
  background-color: #FF9900;
  color: #FFFFFF;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover, .amplify-button:hover {
  background-color: #FFD700;
  color: #121212;
}

a {
  color: #FF9900;
  text-decoration: none;
}

a:hover {
  color: #FFD700;
}

.amplify-label, 
.amplify-input::placeholder {
  color: #FFFFFF;
  opacity: 0.8;
}

.amplify-field-group__label, 
.amplify-input, 
.amplify-form--sign-in h2,
.amplify-form--sign-up h2,
.amplify-input::placeholder {
  color: #FFFFFF !important;
  opacity: 0.9 !important;
}