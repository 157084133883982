/* Base styles */
body, .section, .container, .page-heading, .service, .security, .why-choose-us, .intro-column {
  overflow-x: hidden; /* Prevent horizontal overflow */
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ffffff;
  background-color: #000000;
  margin: 0;
  padding: 0;
}

/* Container */
.container {
  padding: 2vh 1vw; /* Adjust padding based on viewport size */
  max-width: 75% ; /* 75vw Adjust the max width based on the viewport */
  margin: 0 auto;
  width: calc(100% - 4vw);
}

/* Base styles */
.header {
  background-color: #000000; /* Same background color as the body */
  color: #ffffff;
  padding: 2vh 1vw; /* Match padding with container */
  max-width: 75%; /* Match max-width with container */
  margin: 0 auto; /* Center the header */
  width: calc(100% - 4vw); /* Match width calculation */
}

/* Header Top section */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh; /* Space between top and navigation */
}

/* Logo and Company Information */
.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px; /* Adjust logo size as needed */
  margin-right: 20px;
}

.company-info {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-size: 2.5rem; /* Match with .page-heading h1 */
  margin: 0;
}

.slogan {
  font-size: 1.25rem; /* Match with .page-heading p */
  margin: 0;
  color: #FF9900; /* Use the same color for consistency */
}

/* Contact Information on the Right */
.header-right {
  font-size: 1.25rem; /* Adjust as needed */
}

.contact-info {
  margin: 0;
  font-weight: bold;
}

/* Navigation */
.navigation {
  display: flex;
  justify-content: center;
  background-color: #333333; /* Adjust as needed */
  padding: 10px 0;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation li {
  margin: 0 15px;
}

.navigation a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.navigation a:hover {
  color: #FF9900; /* Match hover color */
}

/* Page Heading */
.page-heading {
  text-align: center;
  margin-bottom: 2vh;
  margin-top: 2vh;
  background-color: #615454;
  padding: 5vh 2vw; /* Adjust padding based on viewport size */
  border-radius: 3vh;
  box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
}

.page-heading h1 {
  color: #FF9900;
  font-size: 2.5rem; /* Changed from vw to rem */
}

.page-heading p {
  color: #ffffff;
  font-size: 1.25rem; /* Changed from vw to rem */
}

/* Two-column layout for introduction text */
.page-heading .intro {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3vw;
  text-align: left;
}

.page-heading .intro p {
  flex: 1;
  min-width: 30vw; /* Adjust min-width based on viewport width */
}

.page-heading .intro .intro-column {
  flex: 1;
  min-width: 30vw; /* Adjust min-width based on viewport width */
}

/* Section headings */
.section-heading {
  color: #FF9900;
  text-align: center;
  font-size: 2.5rem; /* Changed from vw to rem */
  margin-bottom: 2vh;
}

/* Service blocks */
.service {
  display: flex;
  align-items: center;
  margin-bottom: 5vh;
  padding: 3vh 2vw;
  background: linear-gradient(145deg, #6a5d5d, #514949);
  border-radius: 3vh;
  box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
  gap: 3vw;
  width: 100%; /* Ensures the service block takes the full width available */
}

/* Default: Image on the left, text on the right */
.service:nth-child(odd) {
  flex-direction: row;
}

/* Alternate: Text on the left, image on the right */
.service:nth-child(even) {
  flex-direction: row-reverse;
}

/* Media inside the service block */
.service-media {
  flex: 1 1 auto; /* Allows the media to adjust based on content */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Adjust media */
.service img,
.service-video {
  width: 100%;
  height: auto;
  border-radius: 2vh;
  box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
}

.service-content {
  flex: 2 1 40vw; /* Allow the text to shrink but not less than 40vw */
  min-width: 30vw; /* Ensure the text does not shrink below 30vw */
  max-width: 75%;
  flex-direction: column; /* Stack the text and button vertically */
  align-items: center; /* Center-align the button horizontally */
  margin-top: 2vh; /* Add spacing between media and text */
  text-align: left;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* Security and Why Choose Us sections */

.security {
  margin-bottom: 5vh;
  padding: 3vh 2vw; /* Adjust padding based on viewport size */
  background: linear-gradient(145deg, #6a5d5d, #514949);
  border-radius: 3vh;
  box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
}

/* Wrapper to hold the content and media in two columns */
.security-content-wrapper {
  display: flex;
  align-items: center;
  gap: 3vw;
}

/* Content on the left */
.security-content {
  flex: 2 1 40vw; /* Allow the text to shrink but not less than 40vw */
  min-width: 30vw; /* Ensure the text does not shrink below 30vw */
  max-width: 100%;
  text-align: left;
}

/* Media on the right */
.security-media {
  flex: 1 1 35vw; /* Allow the image to shrink but not less than 35vw */
  display: flex;
  justify-content: center;
  align-items: center;
}

.security img {
  max-width: 100%;
  height: auto;
  border-radius: 2vh;
  box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
}

.why-choose-us {
  text-align: center;
  padding: 5vh 2vw;
  max-width: 60vw; /* Adjust max width based on viewport width */
  margin-left: auto;
  margin-right: auto;
}

.why-choose-us ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
}

/* Why Choose Us */
.why-choose-us li {
  margin-bottom: 2vh;
  font-size: 1.5rem; /* Changed from vw to rem */
  color: #ffffff;
}

.why-choose-us p {
  margin-top: 2vh;
  font-size: 1.5rem; /* Changed from vw to rem */
  text-align: left;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .header {
    width: calc(100% - 4vw); /* Match with the container on smaller screens */
  }
}

@media (max-width: 922px) {
  .header-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-left, .header-right {
    width: 100%;
    text-align: center;
    margin-bottom: 10px; /* Add space between stacked elements */
  }

  .company-name {
    font-size: 2rem; /* Adjust for smaller screens */
  }

  .slogan {
    font-size: 1rem; /* Adjust for smaller screens */
  }

  .navigation {
    flex-direction: column;
    align-items: center;
  }

  .navigation li {
    margin: 5px 0;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .container {
    width: calc(100% - 4vw);
  }
}

/* Responsive adjustments for the page heading */
@media (max-width: 922px) {
  .page-heading h1 {
    font-size: 4vw; /* Adjust font size based on viewport width */
  }

  .page-heading p {
    font-size: 2vw; /* Adjust font size based on viewport width */
  }

  .page-heading .intro {
    flex-direction: column;
  }
}

/* Responsive adjustments for service blocks */
@media (max-width: 1000px) {
  .service {
    flex-direction: column !important; /* Stack the image/video on top of the text */
    align-items:center;
    text-align: left;
  }

  .service img,
  .service-video {
    width: 100%;
    height: auto;
    max-width: 100%; /* Ensures the media doesn't exceed the container's width */
    object-fit: contain; /* Maintain the aspect ratio */
    border-radius: 2vh;
    box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
  }

.cta-button {
    width: 100%;
    text-align: center;
  }
}

/* Responsive adjustments for security section */
@media (max-width: 1000px) {
  .security-content-wrapper {
    flex-direction: column; /* Stack the image/video on top of the text */
    text-align: left;
  }

  .security img {
    width: 100%;
    height: auto;
    max-width: 100%; /* Ensures the media doesn't exceed the container's width */
    object-fit: contain; /* Maintain the aspect ratio */
    border-radius: 2vh;
    box-shadow: 0 1vh 2vh rgba(200, 191, 191, 0.4), 0 0.6vh 0.6vh rgba(0, 0, 0, 0.2);
  }

  .security-content {
    text-align: left;
    margin-top: 2vh; /* Add spacing between media and text */
  }
}

/* Responsive adjustments for Why Choose Us section */
@media (max-width: 922px) {
  .why-choose-us {
    max-width: 80vw; /* Adjust max width based on viewport width */
    padding: 5vh 3vw; /* Adjust padding based on viewport size */
  }

  .why-choose-us p {
    font-size: 2vw; /* Adjust font size based on viewport width */
  }
}