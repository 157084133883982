/* Header styles */
.header {
    background-color: #000000; /* Adjust color as needed */
    padding: 20px 0; /* Adjust padding as needed */
    color: #ffffff;
  }
  
  .header-container {
    max-width: 75vw; /* Same as the HomePage container width */
    margin: 0 auto; /* Center the header */
    padding: 0 1vw; /* Optional padding to match HomePage */
  }
  
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space between top and navigation */
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 60px; /* Adjust logo size */
    margin-right: 20px;
  }
  
  .company-info {
    display: flex;
    flex-direction: column;
  }
  
  .company-name {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .slogan {
    font-size: 1rem;
    margin: 0;
    color: #FF9900; /* Adjust color as needed */
  }
  
  .header-right {
    font-size: 1.2rem;
  }
  
  .contact-info {
    margin: 0;
    font-weight: bold;
  }
  
  .navigation {
    display: flex;
    justify-content: center;
    background-color: #333333; /* Adjust as needed */
    padding: 10px 0;
  }
  
  .navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navigation li {
    margin: 0 15px;
  }
  
  .navigation a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .navigation a:hover {
    color: #FF9900; /* Adjust hover color */
  }

  .header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .user-info {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .logout-link {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .logout-link:hover {
    text-decoration: underline;
  }

  .logout-link {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
  }